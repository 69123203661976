"use client"
import clsx from "clsx"
import { useState } from "react"
import { useForm } from "react-hook-form"
import { BiCheckCircle } from "react-icons/bi"

import Button from "@modules/common/components/button"
import wave from "@assets/images/wave.jpg"

const Newsletter = () => {
  const [isSent, setIsSent] = useState(false)
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isLoading, isSubmitting },
  } = useForm<{ email: string }>({
    mode: "onSubmit",
  })

  const onApply = async (data: { email: string }) => {
    await fetch("/api/newsletter-signup", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(() => {
        setIsSent(true)
        reset()
      })
      .catch((e) => {
        console.log(e)
      })
  }

  return (
    <div className="mt-12 py-16 px-4 lg:px-0 relative border-t">
      <div
        className="absolute w-full top-0 left-0 h-full bg-center bg-cover bg-no-repeat opacity-20"
        style={{ backgroundImage: `url(${wave.src})` }}
      />
      <div className="content-container relative">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 items-start">
          <div className="lg:pr-20">
            <p className="text-2xl lg:text-3xl text-primary mb-4 font-heading tracking-widest">
              Anmäl dig till vårt Nyhetsbrev
            </p>
            <p>
              Prenumerera på vårt nyhetsbrev nu och var bland de första att få
              de senaste uppdateringarna.
            </p>
          </div>
          <form
            onSubmit={handleSubmit(onApply)}
            className="w-full form-control"
          >
            {isSent ? (
              <div className="alert alert-success mb-4">
                <BiCheckCircle /> Du har nu anmält dig till vårt nyhetsbrev.
              </div>
            ) : null}
            <div className="flex justify-between items-start">
              <label className="label text-primary flex-1 w-full">
                Din mejladress:
              </label>
              <Button
                className="flex-0 max-w-[220px]"
                disabled={isLoading || isSubmitting}
                isLoading={isLoading}
              >
                Prenumerera
              </Button>
            </div>
            <input
              className={clsx(
                "w-full bg-transparent px-2 py-2 border-b border-secondary flex-1 focus:outline-none",
                {
                  "border-error mb-2": errors?.email,
                }
              )}
              placeholder=""
              autoComplete="email"
              {...register("email", {
                required: "Ange mejladress",
                pattern: {
                  value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/i,
                  message: "Felaktig mejladress",
                },
              })}
            />
            {errors.email ? (
              <p className="text-error mt-2 text-xs">{errors.email.message}</p>
            ) : null}
          </form>
        </div>
      </div>
    </div>
  )
}

export default Newsletter
